import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'

//�б�
export const purOrderList = (page, pageSize, vendor, code, batch, color, po, beginTime, endTime, id,brandBusinessId) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrders_Agen",
        method: "get",
        params: {
            "code": code,
            "batch": batch,
            "color": color,
            "vendor": vendor,
            "po": po,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "id": id,
            "brandBusinessId": brandBusinessId,
            "page": page,
            "pageSize": pageSize
        }
    })
}


//���ݲɹ�id��ȡ����������Ϣ
export const SoListByPoId = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrderListByPOId",
        method: "get",
        params: {
            "id": id
        }
    })
}



//���ݲɹ���ϸid��ȡ����������Ϣ
export const SoListByPoDetailId = (id, brandBusinessId) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrderListByPoDetailId_Agen",
        method: "get",
        params: {
            "id": id,
            "brandBusinessId": brandBusinessId,
        }
    })
}

//���ݺ�ͬid��ȡ�ɹ���Ϣ
export const POListById = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetPurchaseOrderListByContractId",
        method: "get",
        params: {
            "id": id
        }
    })
}


//��ȡpo��������Ϣ
export const PoAndDetailList = (page, pageSize, vendor, code, batch, color, no_SeaCodeVal,seaCode, beginTime, endTime) => {
    return request({
        url: pvhApiBase + "Tracing/GetPoAndDetailList",
        method: "get",
        params: {
            "code": code,
            "batch": batch,
            "color": color,
            "vendor": vendor,
            "no_SeaCodeVal": no_SeaCodeVal,
            "seaCode": seaCode,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//����
export const addPOHead = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PostPurchaseOrder_Agen",
        method: "post",
        data: {
            ...params
        }
    })
}

//�༭
export const editHead = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutPurchaseOrder_Agen",
        method: "put",
        data: {
            ...params
        }
    })
}

//�ָ�
export const recoverHead = (id) => {
    return request({
        url: pvhApiBase + "Tracing/RecoverPurchaseOrder",
        method: "delete",
        params: {
            id
        }
    })
}

//ɾ��
export const delHead = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeletePurchaseOrder",
        method: "delete",
        params: {
            id
        }
    })
}

//����
export const addPOBody = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PostPurchaseOrderDetail_Agen",
        method: "post",
        data: {
            ...params
        }
    })
}

//�༭
export const editBody = (params) => {
    return request({
        url: pvhApiBase + "Tracing/PutPurchaseOrderDetail_Agen",
        method: "put",
        data: {
            ...params
        }
    })
}

//ɾ��
export const delBody = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeletePurchaseOrderDetail",
        method: "delete",
        params: {
            id
        }
    })
}


//vendorvection ��Ӧ�����
export const vendorvectionlist = () => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetCorporations",
        method: "get",
    })

}






export function getDate(d) {
    var date = new Date(d); //���ڶ���
    var yTime = date.getFullYear();
    var MTime = date.getMonth() + 1;
    var dTime = date.getDate();
    var hTime = date.getHours();
    var Ftime = date.getMinutes();
    var sTime = date.getSeconds();
    if (MTime < 10) {
        MTime = "0" + MTime;
    }
    if (dTime < 10) {
        dTime = "0" + dTime;
    }
    if (hTime < 10) {
        hTime = "0" + hTime;
    }
    if (Ftime < 10) {
        Ftime = "0" + Ftime;
    }
    if (sTime < 10) {
        sTime = "0" + sTime;
    }
    return yTime + "-" + MTime + "-" + dTime + " " + hTime + ":" + Ftime ;
}


//����
export const addContractHead = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PostContract_Agen",
        method: "post",
        data: {
            ...params
        }
    })
}

//����
export const addContractBody = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PostContractDetail_Agen",
        method: "post",
        data: {
            ...params
        }
    })
}
